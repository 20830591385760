<template>
  <div class="contact">
    <content-wrapper-block>
      <bread-crumbs />
      <page-title white> контакты </page-title>
      <!-- <card-adress
        title="График работы офиса продаж в праздники"
        work-time="<b style='color: #FFF'>12-14 июня </b> - с 10:00 - 20:00"
      /> -->
      <div class="contact-block">
        <card-adress
          class="adress-building"
          title="ЖК MAINSTREET"
          :address="buildingAddress"
          :work-time="buildingWorkTime"
        >
          <template slot="icon">
            <icon-location :color="'#E14313'" />
          </template>
          <p>
            Агентствам недвижимости по вопросам сотрудничества: <a class="phone" href="tel:+74954322393">+7 (495) 432-23-93</a>
          </p>
        </card-adress>
        <div class="item item__button contact-button">
          <ui-button

            data-popup-with-chats
            data-popup-with-chats-title="Получить информацию о проекте"
            data-popup-with-chats-source-id="contact-block__presentation"
          >
            Получить информацию о проекте
          </ui-button>
        </div>

        <card-adress
          class="adress-office"
          title="офис продаж"
          :address="ofiiceAddress"
          :work-time="officeWorkTime"
        >
          <template slot="icon">
            <icon-location :color="'#83B4FF'" />
          </template>
        </card-adress>

        <div class="item feedback-social">
          <div class="item__phone phone-content feedback-social_number">
            <a
              type="number"
              :href="'tel:' + phone"
              class="phone-content__number comagic_phone1"
              >{{ phone }}</a
            >
            <p
              class="phone-content__recall"
              data-popup-with-chats
              data-popup-with-chats-title="Перезвоните мне"
            >
              Перезвоните мне
            </p>
          </div>
          <div class="item__social feedback-social_massege">
            <a
              class="social__link"
              href="https://t.me/kvartal_mainstreet"
              target="__blank"
            >
              <icon-telegram />
            </a>
            <a
              class="social__link"
              href="https://vk.com/osnova_mainstreet"
              target="__blank"
            >
              <icon-vk />
            </a>
            <!-- <icon-whatsapp /> -->
            <!-- <icon-viber /> -->
          </div>
        </div>

        <div class="item image">
          <img src="@/assets/images/contacts/office.jpg" alt="" />
        </div>
      </div>
    </content-wrapper-block>
  </div>
</template>

<script>
import PageTitle from "@/components/elements/PageTitle";
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import CardAdress from "@/components/elements/CardAdress";
import IconLocation from "@/components/icons/IconLocation";
import UiButton from "@/components/ui/UIButton";
import IconTelegram from "@/components/icons/IconTelegram";
import IconVk from "@/components/icons/IconVk";
// import IconWhatsapp from '@/components/icons/IconWhatsapp'
// import IconViber from '@/components/icons/IconViber'
import BreadCrumbs from "@/components/elements/BreadCrumbs";

export default {
  name: "FullContactBlock",
  components: {
    BreadCrumbs,
    ContentWrapperBlock,
    PageTitle,
    CardAdress,
    IconLocation,
    UiButton,
    IconTelegram,
    IconVk,
    // IconWhatsapp,
    // IconViber,
  },
  props: {
    phone: {
      type: String,
      default: "",
    },
    buildingAddress: {
      type: String,
      default: "",
    },
    buildingWorkTime: {
      type: String,
      default: "",
    },
    ofiiceAddress: {
      type: String,
      default: "",
    },
    officeWorkTime: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  background: #231f20;
  padding-bottom: 24px;

  @media screen and (min-width: $screen-md) {
    padding-bottom: 67px;
  }

  &-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-auto-flow: row;
    grid-template-areas:
      "buildingAddress buildingAddress"
      "ofiiceAddress ofiiceAddress"
      "social social"
      "img img"
      "button .";
    row-gap: inherit;
    justify-content: stretch;

    @media screen and (min-width: $screen-sm) {
      grid-template-areas:
        "buildingAddress ofiiceAddress"
        "social social"
        "img img"
        "button .";
      grid-template-columns: 1fr 2fr;
      margin-top: 40px;
    }

    @media screen and (min-width: $screen-md) {
      grid-template-columns: 1fr 1fr 2fr;
      grid-template-rows: auto;
      grid-auto-flow: row;
      grid-template-areas:
        "buildingAddress ofiiceAddress img"
        "button social img";
      row-gap: inherit;
      justify-content: stretch;
    }

    @media screen and (min-width: $screen-lg) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr auto;
      grid-auto-flow: column;
      row-gap: 20px;
      grid-template-areas:
        "buildingAddress ofiiceAddress img"
        "button social img";
    }

    .adress-building {
      grid-area: buildingAddress;
    }

    .contact-button {
      grid-area: button;
      grid-column: 1/3;
      margin-top: 20px;

      .ui-button {
        padding: 12px 15px;
        font-size: 12px;
      }
    }

    .adress-office {
      grid-area: ofiiceAddress;

      @media screen and (min-width: 360px) {
        justify-self: initial;
      }
    }

    .feedback-social {
      grid-area: social;
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-template-areas: "number massage";
      margin: 15px 0px 20px 0;

      @media screen and (min-width: 360px) {
        grid-template-columns: 1fr 1fr;
      }

      @media screen and (min-width: $screen-sm) {
        grid-template-columns: 1fr 2fr;
      }

      @media screen and (min-width: $screen-md) {
        display: block;
      }

      &_number {
        grid-area: number;
      }

      &_massege {
        grid-area: massage;
        // justify-self: end;

        @media screen and (min-width: 360px) {
          justify-self: initial;
        }
      }

      @media screen and (min-width: $screen-sm) {
        grid-template-columns: 1fr 2fr;
      }
    }

    .image {
      grid-area: img;
      grid-row: inherit;

      @media screen and (min-width: $screen-md) {
        grid-row: 1 / 3;
        margin-bottom: 20px;
      }

      img {
        width: 100%;
        @media screen and (min-width: $screen-lg) {
          width: initial;
        }
      }
    }
  }
  .item {
    .phone-content {
      color: #fff;

      &__number {
        font-size: 20px;
        color: #fff;
        text-decoration: none;

        @media screen and (min-width: $screen-xs) {
          font-size: 26px;
        }

        &:hover {
          text-decoration: underline;
          opacity: 0.9;
        }
      }

      &__recall {
        font-family: Rubik;
        text-decoration: underline;
        font-size: 14px;
        margin: 0;
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__phone {
      line-height: 1.3;
    }

    &__social {
      margin-top: 0px;

      @media screen and (min-width: $screen-md) {
        margin-top: 20px;
      }

      svg {
        margin-left: 15px;
        cursor: pointer;
        margin-left: -25px;

        @media screen and (min-width: 360px) {
          margin-left: 0;
        }
        // &:first-child {
        //   margin-left: -25px;
        // }
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
</style>
