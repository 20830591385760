var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contact-page" },
    [
      _c("contacts-block", {
        attrs: {
          phone: "+7 (495) 432-40-97",
          "building-address": _vm.address,
          "ofiice-address": _vm.office,
          "office-work-time": _vm.workTime,
        },
        on: { call: _vm.feedback },
      }),
      _c("Map"),
      _c("callback-block", {
        attrs: { id: "callback", "id-formblock": "question-block__callback" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }