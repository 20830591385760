var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contact" },
    [
      _c(
        "content-wrapper-block",
        [
          _c("bread-crumbs"),
          _c("page-title", { attrs: { white: "" } }, [_vm._v(" контакты ")]),
          _c(
            "div",
            { staticClass: "contact-block" },
            [
              _c(
                "card-adress",
                {
                  staticClass: "adress-building",
                  attrs: {
                    title: "ЖК MAINSTREET",
                    address: _vm.buildingAddress,
                    "work-time": _vm.buildingWorkTime,
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "icon" },
                    [_c("icon-location", { attrs: { color: "#E14313" } })],
                    1
                  ),
                  _c("p", [
                    _vm._v(
                      " Агентствам недвижимости по вопросам сотрудничества: "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "phone",
                        attrs: { href: "tel:+74954322393" },
                      },
                      [_vm._v("+7 (495) 432-23-93")]
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "item item__button contact-button" },
                [
                  _c(
                    "ui-button",
                    {
                      attrs: {
                        "data-popup-with-chats": "",
                        "data-popup-with-chats-title":
                          "Получить информацию о проекте",
                        "data-popup-with-chats-source-id":
                          "contact-block__presentation",
                      },
                    },
                    [_vm._v(" Получить информацию о проекте ")]
                  ),
                ],
                1
              ),
              _c(
                "card-adress",
                {
                  staticClass: "adress-office",
                  attrs: {
                    title: "офис продаж",
                    address: _vm.ofiiceAddress,
                    "work-time": _vm.officeWorkTime,
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "icon" },
                    [_c("icon-location", { attrs: { color: "#83B4FF" } })],
                    1
                  ),
                ],
                2
              ),
              _c("div", { staticClass: "item feedback-social" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "item__phone phone-content feedback-social_number",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "phone-content__number comagic_phone1",
                        attrs: { type: "number", href: "tel:" + _vm.phone },
                      },
                      [_vm._v(_vm._s(_vm.phone))]
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "phone-content__recall",
                        attrs: {
                          "data-popup-with-chats": "",
                          "data-popup-with-chats-title": "Перезвоните мне",
                        },
                      },
                      [_vm._v(" Перезвоните мне ")]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "item__social feedback-social_massege" },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "social__link",
                        attrs: {
                          href: "https://t.me/kvartal_mainstreet",
                          target: "__blank",
                        },
                      },
                      [_c("icon-telegram")],
                      1
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "social__link",
                        attrs: {
                          href: "https://vk.com/osnova_mainstreet",
                          target: "__blank",
                        },
                      },
                      [_c("icon-vk")],
                      1
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "item image" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/contacts/office.jpg"),
                    alt: "",
                  },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }