<template>
  <div class="contact-page">
    <contacts-block
      phone="+7 (495) 432-40-97"
      :building-address="address"
      :ofiice-address="office"
      :office-work-time="workTime"
      @call="feedback"
    />
    <Map />
    <callback-block id="callback" id-formblock="question-block__callback" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ContactsBlock from "@/components/blocks/FullContactsBlock";
import CallbackBlock from "@/components/blocks/CallbackBlock";
import ProgressBlock from "@/components/blocks/ProgressBlock";
import Map from "@/components/elements/Map";

export default {
  name: "ContactPage",
  components: {
    ContactsBlock,
    CallbackBlock,
    ProgressBlock,
    Map,
  },
  metaInfo: {
    meta: [
      {
        name: "description",
        content: "Офис продаж ЖК MainStreet: ул. Ивана Франко, 8",
      },
    ],
  },
  computed: {
    ...mapState({
      building: (state) => state.building,
      workTime: (state) => state.workTime,
      storage: (state) => state.storage,
    }),
    address () {
      if (!this.storage || !this.storage.addresses) {
        return ''
      }
      return '<p class="marginss">' + this.storage.addresses.addr_list[0].addr_location + '</p>'
    },
    office () {
      if (!this.storage || !this.storage.addresses) {
        return ''
      }
      return '<p>' + this.storage.addresses.addr_list[1].addr_location + '</p>'
    }
  },
  methods: {
    feedback() {},
  },
};
</script>

<style lang="scss" scoped>
.contact-page {
  .map {
    border: none;

    @media screen and (min-width: $screen-sm) {
      border-bottom: 3px solid #231f20;
    }
  }

  .progress-block {
    border-bottom: 3px solid #231f20;
    padding: 20px 0 20px 0;
    @media screen and (min-width: $screen-sm) {
      border: none;
      padding: 35px 0 30px 0;
    }
  }
}
</style>
